.root {
    position: fixed;
    transition: 300ms;
    left: 0;
    width: 100%;
    height: fit-content;
    color: var(--color-backgroundgray);
    background-color: var(--color-white);
    bottom: 0;
    z-index: 201;
    font-family: var(--fonts-poppins-regular);
    -webkit-box-shadow: 5px 5px 15px 5px #f1f1f1;
    box-shadow: 5px 5px 15px 5px #f1f1f1;
}

.shown {
    left: 0 !important;
}

.body {
    position: relative;
    padding: 1rem 5%;
}

.langSection {
    position: absolute;
    padding-bottom: 0.1rem;
    top: 0.5rem;
    right: 0.5rem;
    cursor: pointer;
    z-index: 3;
    border-bottom: 1px solid;
    display: none;
}

.closeBtn {
    position: absolute;
    padding: 0.2rem;
    top: 0;
    right: 0;
    cursor: pointer;
    z-index: 3;
}

.content {
    display: grid;
    grid-template-columns: 1fr 100px;
    max-width: 1092px;
    margin: 0 auto;
    grid-gap: 1.25rem;
}

.policyText {
    font-size: 0.875rem;
    line-height: 1.4;
}

.policyText a {
    text-decoration: underline;
}

.button {
    border-radius: 2rem;
    font-family: var(--fonts-poppins-regular);
    font-weight: 700;
}

.acceptAllBtn {
    composes: button;
    background-color: white;
    color: var(--color-backgroundgray);
    border: 1px solid var(--color-backgroundgray);
    padding: 0.5rem 2.25rem;
    margin: 0;
    font-size: 1rem;
}

.preferencesBtn {
    composes: button;
    text-decoration: underline;
}

.popupContainer {
    width: 50vw;
    height: fit-content;
    min-height: 20vh;
    background-color: white;
}

.popupBody {
    display: block;
    position: relative;
    padding: 2.5rem;
    font-family: var(--fonts-poppins-regular);
}

.popupCookieGroups {
    height: 320px;
    overflow-y: auto;
}

/* width */
.popupCookieGroups::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.popupCookieGroups::-webkit-scrollbar-track {
    border-radius: 10px;
    box-shadow: inset 0 0 2px black;
}

/* Handle */
.popupCookieGroups::-webkit-scrollbar-thumb {
    background: black;
    border-radius: 10px;
}

/* Handle on hover */
.popupCookieGroups::-webkit-scrollbar-thumb:hover {
    background: #3c3c3c;
}

.popupTitle {
    font-size: 1.5rem;
    font-weight: 700;
    padding-bottom: 2rem;
    letter-spacing: 0.05rem;
    margin-right: 2rem;
}

.popupCookieGroups input[type="checkbox"] {
    border-width: 2px;
    width: 1rem;
    height: 1rem;
}

.popupCookieGroup {
    padding-bottom: 2rem;
}

.popupGroupTitleSection {
    display: flex;
    flex-direction: row;
}

.popupGroupTitleSection * {
    cursor: pointer;
}

.isNecessary * {
    cursor: not-allowed !important;
}

.popupGroupTitle {
    font-size: 1.25rem;
    font-weight: 700;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.popupGroupDescription {
    padding-top: 0.5rem;
}

@media (max-width: 1248px) {
    .policyText {
        padding-right: 0;
        font-size: 0.85rem;
    }
    .popupContainer {
        width: 90vw;
        max-height: 80vh;
    }
    .body {
        position: relative;
        padding: 1rem 1.25rem;
    }
}

@media (max-width: 767px) {
    .button {
        font-size: 0.85rem;
    }
    .policyText {
        font-size: 0.75rem;
    }
    .content {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 0.5rem;
    }
    .body {
        position: relative;
        padding: 0.75rem 1.25rem;
    }
    .acceptAllBtn {
        padding: 0.25rem 2.25rem;
    }
}


:global(#attentive_overlay) {
    z-index: 200 !important;
}

:global(.needsclick[role="dialog"]){
    z-index: 198 !important;
}
