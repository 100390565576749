.myAccountMenu {
    position: absolute;
    top: 100%;
    width: 25%;
    z-index: 1000;
    font-size: 20px;
    background-color: var(--color-white);
    transition: 500ms;
}

.init {
    display: none;
    z-index: 0;
}

.user {
    width: 100%;
    padding: 35px;
    display: flex;
    z-index: 5;
    justify-content: space-between;
    align-items: center;
    background-color: white;
}

.user > img {
    cursor: pointer;
}


.option {
    cursor: pointer;
    display: flex;
    background-color: var(--color-whitegrayish);
    justify-content: start;
    align-items: center;
    padding: 25px 35px;
}

.optionSignOutContainer {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 15px 30px;
}

.sectionHeaderName{
    font-family: var(--fonts-poppins-bold);
    line-height: 30px;
}

.redButton {
    background-color: var(--color-red);
    width: 100%;
    padding: 20px 0;
    border-radius: 35px;
    color: var(--color-white);
    font-family: var(--fonts-poppins-bold);
    font-size: 18px;
    letter-spacing: 0.97px;
    text-align: center;
}

.option div {
    display: flex;
    align-items: center;
}

.option div span {
    padding-left: 20px;
}

.option:hover {
    background-color: white;
}

.menuOption {
    padding: 1.5rem 2rem;
    transition: 300ms;
    cursor: pointer;
}

.optionName {
    white-space: pre-wrap;
    padding-right: 0.75rem;
}

.accountOptionName {
    display: grid;
    align-items: center;
    justify-items: left;
    grid-template-columns: max-content auto;
    column-gap: 1.25rem;
}


/*because fonts are bad*/
.menuOption span {
    margin-top: 1px;
}

.menuOption:hover {
    background-color: var(--color-lightergray);
}

.openAccountMenu {
    animation: openAccountMenu 0.5s forwards;
    box-shadow: 0 3px 3px 1px rgba(0,0,0,0.4);
}

.closeAccountMenu {
    animation: closeAccountMenu 0.5s forwards;
}

.openAccountMenu > * {
    animation: SlowAppear 1s forwards;
}

.closeAccountMenu > * {
    animation: SlowDisappear 0.5s forwards;
}

.backArrow {
    animation: backArrow 1s forwards;
}

.option::after {
    content: url('../../../venia-static/icons/arrow.svg');
    position: absolute;
    right: 20px;
    opacity: 0;
    transition: all 0.3s;
}

.option:hover::after {
    content: url('../../../venia-static/icons/arrow.svg');
    opacity: 1;
    transform: rotate(90deg);
}


@media (max-width: 1248px) {

    .myAccountMenu {
        position: absolute;
        top: 100%;
        z-index: 1000;
        font-size: 20px;
        background-color: var(--color-white);
        transition: 500ms;
        right: 0;
        overflow: hidden;
        width: 90%;
    }

    .storeOptionsContainer {
        margin-bottom: 15rem;
    }

    .menuOption {
        width: 100%;
        display: grid;
        grid-auto-flow: column;
        background-color: white;
        justify-content: space-between;
        align-items: center;
        padding: 1.25rem 1.25rem;
        border-top: 1px solid var(--color-footergray);
        font-family: var(--fonts-poppins-regular);
        font-weight: 600;
        text-align: left;
        white-space: pre-wrap;
    }

    .openAccountMenu {
        animation: openAccountMenuMobile 0.5s forwards;
        box-shadow:
            3px 3px 3px 1px rgba(0,0,0,0.4),
            0 100px 0 100px rgba(255,255,255,0.75);

    }

    .closeAccountMenu {
        animation: closeAccountMenuMobile 0.5s forwards;
    }

    .menuMobileItems {
        display: flex;
        width: 200%;
        position: relative;
        transform: translateX(0);
        transition: var(--base-transition);
    }

    .menuMobileItemsOpen {
        composes: menuMobileItems;
        transform: translateX(-50%);
    }

    .accountMenuAccount {
        width: 50%;
        position: relative;
    }

    .menu {
        width: 50%;
        overflow-y: scroll;
        overflow-x: hidden;
        max-height: calc(100vh - 200px);
    }

    .menuBack {
        composes: menu;
        max-height: calc(100vh - 320px)
    }


    .user {
        background-color: var(--color-whitegrayish);
    }


    .userMobile {
        background-color: var(--color-whitegrayish);
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 18px;
        position: relative;
    }

    .back {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        display: flex;
    }
    .userMobile .back::before {
        content: url('../../../venia-static/icons/arrow.svg');
        position: relative;
        transform: rotate(90deg);
        transition: var(--base-transition);
    }
    .userMobile .back span {
        display: none;
        padding-left: 0.5rem;
    }
    .userMobileOpen {
        composes: userMobile;
    }

    .userMobileOpen .back::before {
        transform: rotate(-90deg);
    }

    .userMobileOpen .back span {
        display: block;
    }

    .myAccountMobile {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: max-content;
        gap: 1rem;
        justify-items: left;
        align-items: center;
        width: 100%;
        white-space: nowrap;
    }

    .myAccountBack {
        display: flex;
        align-items: center;
    }

    .optionHeader {
        display: flex;
        background-color: white;
        justify-content: space-between;
        align-items: center;
        padding: 1.25rem 1.25rem 1.25rem 0.75rem;
        font-weight: 400;
        border-top: 2px solid var(--color-footergray);
        border-bottom: 1px solid var(--color-footergray);
        cursor: pointer;
    }

    .backButton > img {
        transition: all 1s;
        transform: rotate(270deg);
        height: 20px;
        width: 20px;
        margin-right: 10px;
    }

    .backButton {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-family: var(--fonts-poppins-regular);
        font-size: 1rem;
    }

    .redButton {
        composes: menuOption;
        background-color: transparent;
        border-radius: 0;
        color: var(--color-red);

    }

}


@keyframes menuOption {
    0% {
        opacity: 0;
        transform: rotate(0deg);
    }
    100% {
        opacity: 1;
        transform: rotate(90deg);
    }
}

.specificOption {
    composes: menuOption;
}

.red {
    color: var(--color-red);
    font-family: var(--fonts-poppins-semibold);
}

.shopall {
    text-decoration: underline;
    font-family: var(--fonts-poppins-regular);
}

.italic {
    font-family: var(--fonts-bornready-regular);
    font-weight: 500;
    font-size: 1.6rem;
    letter-spacing: 2px;
}


@keyframes backArrow {
    0% {
        transform: rotate(0deg);
        opacity: 0;
    }
    100% {
        opacity: 1;
        transform: rotate(-90deg);
    }
}

@keyframes openAccountMenuMobile {
    0% {
        left: -100%;
    }
    100% {
        left: 0;
    }
}

@keyframes closeAccountMenuMobile {
    0% {
        left: 0;
    }
    100% {
        left: -100%;
    }
}

@keyframes openAccountMenu {
    0% {
        right: -25%;
    }
    100% {
        right: 0;
    }
}

@keyframes closeAccountMenu {
    0% {
        right: 0;
    }
    100% {
        right: -25%;
    }
}

@keyframes SlowAppear {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes SlowDisappear {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        z-index: -99;
    }
}

.prerenderHidden {
    visibility: hidden;
}